import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../../_models/user';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-meine-tickets',
  templateUrl: './meine-tickets.component.html',
  styleUrls: ['./meine-tickets.component.scss']
})
export class MeineTicketsComponent implements OnInit {
  titel: string = "Von mir erstellte Tickets";
  user: User;
  selection: Object = {};
  

  constructor(
    private localstorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.selection = {ersteller: params['id']}
   });
  }
  

  ngOnInit(): void {
  
  }

  
  

}
