import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { Informationen } from 'src/app/_models/informationen';
import { InformationenService } from 'src/app/_services/informationen.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  informationen: Informationen[];

  constructor(
    private informationsService: InformationenService
  ) { }

  ngOnInit(): void {
    this.loadInformation();
  }

  public loadInformation(): void {
    this.informationen = [];
    var date: Date = new Date();
    date = new Date(date.setDate(date.getDate() -1));
    
    this.informationsService.getInformations({expirationDate: date }).subscribe((informationen: Informationen[]) => {
      informationen.forEach(element => {
        this.informationen.push(element);
      })
    },
    error => {
      console.log('error');
      console.log(error.message);
    });
  }

}
