import { map } from 'rxjs/operators';
import { User } from './../_models/user';
import { Ticket } from './../_models/ticket';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  private hostUrl = environment.hostUrl;

  constructor(
    private http: HttpClient,
    private localstorageService: LocalStorageService
  ) { }

  public getHeader(accesstoken: string) {
    const headers = ({
      'Authorization': `Bearer ${accesstoken}`
    });
    return headers
  }
  public getTickets(selection: object): Observable<Ticket[]> {
    const user: User = this.localstorageService.getCurrentUser();
    const header = this.getHeader(user.accesstoken);

    return this.http.post<Ticket[]>(`${this.hostUrl}/tickets`,{selection: selection}, {headers: header})
      .pipe(map((tickets: Ticket[]) => {
        return tickets
      }));
  }

  public getSingleTicket(tickedId: string): Observable<Ticket> {
    const user: User = this.localstorageService.getCurrentUser();
    const header = this.getHeader(user.accesstoken);

    return this.http.get<Ticket>(`${this.hostUrl}/ticket/${tickedId}`, {headers: header})
      .pipe(map((ticket: Ticket) => {
        return ticket
      }));
  }

  public addTicket(ticketObject: object): any {
    const user: User = this.localstorageService.getCurrentUser();
    const header = this.getHeader(user.accesstoken);

    return this.http.post<Ticket>(`${this.hostUrl}/ticket`, ticketObject, {headers: header})
      .pipe(map((result: Ticket) => {
        return result
      }));

  }

  public addComment(text: string, ticketId: string): any {
    const user: User = this.localstorageService.getCurrentUser();
    const header = this.getHeader(user.accesstoken);
    return this.http.patch<any>(`${this.hostUrl}/ticket/comment/${ticketId}`, {text: text, ersteller: user.id}, {headers: header})
      .pipe(map((result: any) => {
        return result
      }));
  }

  public updateTicket(ticketId: string, ticketObject: object): any {
    const user: User = this.localstorageService.getCurrentUser();
    const header = this.getHeader(user.accesstoken);
    return this.http.patch<any>(`${this.hostUrl}/ticket/${ticketId}`, ticketObject, {headers: header})
      .pipe(map((result: any) => {
        return result
      }));
  }



}
