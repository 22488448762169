import { element } from 'protractor';
import { Ticket } from '../../../../_models/ticket';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-list-element',
  templateUrl: './ticket-list-element.component.html',
  styleUrls: ['./ticket-list-element.component.scss']
})
export class TicketListElementComponent implements OnInit {
  public stati = [{
    status: 'nicht begonnen',
    style: 'bg-red-100 text-red-800'
  }, {
    status: 'in Bearbeitung',
    style: 'bg-yellow-100 text-yellow-800'
  },{
    status: 'erledigt',
    style: 'bg-green-100 text-green-800'
  },{
    status: 'zurückgestellt',
    style: 'bg-blue-100 text-blue-800'
  }];
  public status = {
    style: '',
    status: ''
  };
  
  @Input() ticket!: Ticket;

  constructor() {
   }

  ngOnInit(): void {
    this.status = this.stati.find((element) => element.status === this.ticket.status);
  }

}
