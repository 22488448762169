import { UsersService } from '../../../../_services/users.service';
import { IUser } from '../../../../_models/user';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-benutzer-list-element',
  templateUrl: './benutzer-list-element.component.html',
  styleUrls: ['./benutzer-list-element.component.scss']
})
export class BenutzerListElementComponent implements OnInit {

  @Input() user: IUser;
  constructor(
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
  }

  public changePassword(event?: Event): void {
    if (!confirm(`Möchten Sie wirklich ein neues Passwort für ${this.user.Nachname}, ${this.user.Vorname} anfordern?`)) return

    this.usersService.resetPassword(this.user._id).subscribe((data: any) => {
    },
    error => {
      console.log('error');
      console.log(error.message)
    })
  }

}
