<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
-->
<div *ngIf="isOpen" class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <section class="absolute inset-y-0 pl-16 max-w-full right-0 flex" aria-labelledby="slide-over-heading">
        <!--
          Slide-over panel, show/hide based on slide-over state.
  
          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
        <div class="w-screen max-w-md">
          <form [formGroup]="updateTicketForm" (ngSubmit)="onSubmit($event)" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
            <div class="flex-1 h-0 overflow-y-auto">
              <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                <div class="flex items-center justify-between">
                  <h2 id="slide-over-heading" class="text-lg font-medium text-white">
                    Ticket bearbeiten
                  </h2>
                  <div class="ml-3 h-7 flex items-center">
                    <button (click)="isOpen = !isOpen" class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                      <span class="sr-only">Close panel</span>
                      <!-- Heroicon name: x -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="mt-1">
                  <p class="text-sm text-indigo-300">
                    Bearbeiten Sie ein Ticket, um ein bestehendes Problem an der Gewerblichen Schule zu lösen.
                  </p>
                </div>
              </div>

              <div *ngIf="message.status"  class="bg-red-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <!-- Heroicon name: x-circle -->
                    <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />        </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">
                      Fehler
                    </h3>
                    <div class="mt-2 text-sm text-red-700">
                      <p>
                        {{ message.text }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>




              <div class="flex-1 flex flex-col justify-between">
                <div class="px-4 divide-y divide-gray-200 sm:px-6">
                  <div class="space-y-6 pt-6 pb-5">
                    <div>
                      <label for="project_name" class="block text-sm font-medium text-gray-900">
                        Titel
                      </label>
                      <div class="mt-1">
                        <input type="text" formControlName="titel" [ngClass]="{ 'border-2 border-red-500': submitted && updateTicketForm.controls.titel.errors  }"  class="px-3 py-3 placeholder-gray-400 text-gray-700 relative  bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full">
                      </div>
                    </div>
                    <div>
                      <label for="description" class="block text-sm font-medium text-gray-900">
                        Beschreibung
                      </label>
                      <div class="mt-1">
                        <textarea formControlName="beschreibung" [ngClass]="{ 'border-2 border-red-500': submitted && updateTicketForm.controls.beschreibung.errors  }" rows="4" class="px-3 py-3 placeholder-gray-400 text-gray-700 relative  bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"></textarea>
                      </div>
                    </div>
                    <div *ngIf="user.isAdmin">
                      <label for="description" class="block text-sm font-medium text-gray-900">
                        Dauer in Std. ( Bsp: 1.5 )
                      </label>
                      <div class="mt-1">
                        <input placeholder="Dauer eingeben..." type="number" formControlName="dauer" [ngClass]="{ 'border-2 border-red-500': submitted && updateTicketForm.controls.dauer.errors  }"  class="px-3 py-3 placeholder-gray-400 text-gray-700 relative  bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full">
                      </div>
                    </div>
                    <div>
                      <label for="description" class="block text-sm font-medium text-gray-900">
                        Kategorie
                      </label>
                      <div class="mt-1">
                        
                        <select formControlName="kategorie" [ngClass]="{ 'border-2 border-red-500': submitted && updateTicketForm.controls.kategorie.errors  }" class=" px-3 py-3 placeholder-gray-400 text-gray-700 relative  bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full">
                          <option selected value="">Kategorie auswählen</option>
                          <option value="{{ category._id }}" *ngFor="let category of categories">{{ category.bezeichnung }}</option>
                        </select>
                      </div>
                    </div>

                    <div>
                      <label for="description" class="block text-sm font-medium text-gray-900">
                        Raum
                      </label>
                      <div class="mt-1">
                        
                        <select formControlName="raum" [ngClass]="{ 'border-2 border-red-500': submitted && updateTicketForm.controls.raum.errors  }" class="px-3 py-3 placeholder-gray-400 text-gray-700 relative  bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full">
                         
                          <option value="{{room._id}}"  *ngFor="let room of rooms">{{room.bezeichnung}}</option>
            
                        </select>
                      </div>
                    </div>

                    <div *ngIf="user.isAdmin">
                      <label for="description" class="block text-sm font-medium text-gray-900">
                        Bearbeiter
                      </label>
                      <div class="mt-1">
                        
                        <select formControlName="bearbeiter" [ngClass]="{ 'border-2 border-red-500': submitted && updateTicketForm.controls.bearbeiter.errors  }" class=" px-3 py-3 placeholder-gray-400 text-gray-700 relative  bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full">
                          <option  value="">noch nicht zugewiesen</option>
                          <option value="{{ user$._id }}" *ngFor="let user$ of users">{{ user$.Nachname }}, {{ user$.Vorname}}</option>
                        </select>
                      </div>
                    </div>

                    <div *ngIf="user.isAdmin && updateTicketForm.controls.bearbeiter.value != ''">

                      <div class="mt-1 flex items-center">
                        <input formControlName="benachrichtigungBearbeiter" id="benachrichtigungBearbeiter"  type="checkbox" class="h-4 w-4 py-2 pl-3 pr-10 text-gray-600 focus:ring-indigo-500 border-gray-300 rounded">
                          <label for="benachrichtigungBearbeiter" class="ml-2 block text-sm font-medium text-gray-900">
                            Benachrichtigung über Änderungen an Bearbeiter per Email senden?
                          </label>

                      </div>
                    </div>

                    <div>
                        <label for="description" class="block text-sm font-medium text-gray-900">
                          Status
                        </label>
                        <div class="mt-1">
                          
                          <select formControlName="status" [ngClass]="{ 'border-2 border-red-500': submitted && updateTicketForm.controls.status.errors  }" class="px-3 py-3 placeholder-gray-400 text-gray-700 relative  bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full">
                            <option value="nicht begonnen">nicht begonnen</option>
                            <option value="in Bearbeitung">in Bearbeitung</option>
                            <option value="erledigt">erledigt</option>
                            <option value="zurückgestellt">zurückgestellt</option>
                          </select>
                        </div>
                      </div>

                    <div>

                      <div class="mt-1 flex items-center">
                        <input formControlName="benachrichtigungErsteller" id="benachrichtigungErsteller"  type="checkbox" class="h-4 w-4 py-2 pl-3 pr-10 text-gray-600 focus:ring-indigo-500 border-gray-300 rounded">
                          <label for="benachrichtigungErsteller" class="ml-2 block text-sm font-medium text-gray-900">
                            Benachrichtigung über Änderungen an Ersteller per Email senden?
                          </label>

                      </div>
                    </div>


                  </div>

                </div>
              </div>
            </div>
            <div class="flex-shrink-0 px-4 py-4 flex justify-end">
              <button type="button" (click)="isOpen = !isOpen" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Abbrechen
              </button>
              <button type="submit" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Änderungen speichern
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>

  <app-loading-screen *ngIf="loading" ></app-loading-screen>
  