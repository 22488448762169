import { map } from 'rxjs/operators';
import { User } from './../_models/user';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Room } from '../_models/room';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  private hostUrl = environment.hostUrl;

  constructor(
    private http: HttpClient,
    private localstorageService: LocalStorageService
  ) { }

  public getHeader(accesstoken: string) {
    const headers = ({
      'Authorization': `Bearer ${accesstoken}`
    });
    return headers
  }

  public getRooms(): Observable<Room[]> {
    const user: User = this.localstorageService.getCurrentUser();
    const header = this.getHeader(user.accesstoken);

    return this.http.get<Room[]>(`${this.hostUrl}/rooms`, {headers: header})
      .pipe(map((rooms: Room[]) => {
        return rooms
      }));
  }
}
