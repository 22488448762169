import { element } from 'protractor';
import { CategoryService } from '../../../_services/category.service';
import { kategorie } from '../../../../../../server/src/_interfaces/kategorie';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { TicketService } from '../../../_services/ticket.service';
import { UsersService } from '../../../_services/users.service';
import { RoomService } from '../../../_services/room.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ticket } from '../../../_models/ticket';
import { Subscription, Observable } from 'rxjs';
import { User, IUser } from '../../../_models/user';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Room } from 'src/app/_models/room';
import { Category } from 'src/app/_models/category';

@Component({
  selector: 'app-update-ticket',
  templateUrl: './update-ticket.component.html',
  styleUrls: ['./update-ticket.component.scss']
})
export class UpdateTicketComponent implements OnInit {
  isOpen: Boolean = false;
  user: User;
  rooms: Room[];
  users: IUser[];
  categories: Category[];

  @Output() addedUser: EventEmitter<any> = new EventEmitter<any>();


  private eventSubscription: Subscription;
  @Input() openEvent: Observable<void>;
  @Input() currentTicket: Ticket;
  ticketId: string;
  ticket: Ticket = {
    _id: '',
    dauer: 0,
    kategorie: {
      _id: '',
      bezeichnung: '',
    },
    ersteller: {
      _id: '',
      Vorname: '',
      Nachname: '',
      IsAdmin: false,
      emailAdresse: ''
    },
    raum: {
      _id: '',
      bezeichnung: ''
    },
    titel: '',
    beschreibung: '',
    status: '',
    comments: []

};


public message = {
  status: false,
  text: ''
}

  updateTicketForm: FormGroup;
  submitted: Boolean = false;
  loading: Boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private localstorageService: LocalStorageService,
    private roomService: RoomService,
    private usersService: UsersService,
    private ticketService: TicketService,
    private categoryService: CategoryService
  ) {
    this.user = this.localstorageService.getCurrentUser();
    this.activatedRoute.params.subscribe(params => {
      this.ticketId = params['id'];
      this.loadTicketAndForm();  
    });
  }
  ngOnInit(): void {
    this.eventSubscription = this.openEvent.subscribe(() => {
      this.isOpen = !this.isOpen;
    });
   


    this.loadRooms();
    this.loadCategories();
    if (this.user.isAdmin) this.loadUsers();
  }
  public loadTicketAndForm(): void {
    this.ticketService.getSingleTicket(this.ticketId).subscribe((data: Ticket) => {
      this.ticket = data;
      var isBearbeiter = this.ticket.bearbeiter ? this.ticket.bearbeiter._id : '';

      this.updateTicketForm = this.formBuilder.group({
        titel: [this.ticket.titel, Validators.required],
        beschreibung: [this.ticket.beschreibung, Validators.required],
        raum: [this.ticket.raum._id, Validators.required],
        bearbeiter: [isBearbeiter],
        dauer: [this.ticket.dauer],
        kategorie: [this.ticket.kategorie._id, Validators.required],
        status: [this.ticket.status, Validators.required],
        benachrichtigungErsteller: [false],
        benachrichtigungBearbeiter: [false]
      });
    });
    
  }

  public loadRooms(): void {
    this.rooms = [];
    this.roomService.getRooms().subscribe((data: Room[]) => {
      data.forEach(element => {
        this.rooms.push(element);
      })
    })
  }
  public loadUsers(): void {
    this.users = [];
    this.usersService.getUsers().subscribe((data: IUser[]) => {
      data.forEach(element => {
        if (element.IsAdmin) this.users.push(element);
      })
    })
  }
  public loadCategories(): void {
    this.categories = [];
    this.categoryService.getCategories().subscribe((data: Category[]) => {
      data.forEach(element => {
        console.log(element);
        this.categories.push(element);
      })
    })
  }

  public onSubmit(event?: Event): void {
    this.submitted = true;
 
    if (this.updateTicketForm.invalid) return;
    this.loading = true;

    var ticket = {
      titel: this.updateTicketForm.controls.titel.value,
      beschreibung: this.updateTicketForm.controls.beschreibung.value.replaceAll('\n', '<br>'),
      raum: this.updateTicketForm.controls.raum.value,
      bearbeiter: this.updateTicketForm.controls.bearbeiter.value,
      status: this.updateTicketForm.controls.status.value,
      benachrichtigungErsteller: this.updateTicketForm.controls.benachrichtigungErsteller.value,
      benachrichtigungBearbeiter: this.updateTicketForm.controls.benachrichtigungBearbeiter.value,
      dauer: this.updateTicketForm.controls.dauer.value,
      kategorie: this.updateTicketForm.controls.kategorie.value,
    }
    this.ticketService.updateTicket(this.ticketId, ticket)
      .pipe(first())
      .subscribe(
        data => {
          this.isOpen = !this.isOpen;
          this.ticket = data;
          this.addedUser.emit(data)
          this.loading = false;
        },
        error => {
         this.message = {
           status: true,
           text: error.message
         }
         this.loading = false;
        }
      )


  }

}
