<div class="md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
    <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
       Alle Benutzer
    </h2>
    </div>
    <div class="mt-3" >
        <div class="mb-3 pt-0">
            <input (input)="filterUsers($event)" id="search" type="text" placeholder="Suchen..." class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"/>
          </div>
    </div>

</div>
<br>
<hr>


<div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
<!-- Replace with your content -->
<!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="filteredUsers.length == 0">
    <h1>Es sind keine Benutzer in dieser Kategorie vorhanden...</h1>
</div>
<!-- This example requires Tailwind CSS v2.0+ -->
<!-- This example requires Tailwind CSS v2.0+ -->
<ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">

  
    <app-benutzer-list-element   [user]="user" *ngFor="let user of filteredUsers"></app-benutzer-list-element>
  
  

  </ul>


</div>

<app-loading-screen *ngIf="loading"></app-loading-screen>


