<!-- This example requires Tailwind CSS v2.0+ -->
<div class="relative bg-white overflow-hidden">
    <div class="max-w-7xl mx-auto">
      <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <app-navbar></app-navbar>
  
        <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div class="sm:text-center lg:text-left">
            <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span class="block xl:inline">Lehrerportal der</span>
              <span class="block text-indigo-600 xl:inline"> Gewerblichen Schule Backnang</span>
            </h1>
            <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Erstellen Sie bei <span class="text-indigo-600">IT-Problemen</span> Tickets und überprüfen Sie deren Status. Dies ermöglicht einen geregelten Ablauf und gesicherten <span class="text-indigo-600">Erfolg</span> innerhalb der Schule. 
            </p>
            <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div class="rounded-md shadow">
                <a routerLink="/dashboard" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                  Zum Dashboard
                </a>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-3">
                <a routerLink="/login" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                  Login
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80" alt="">
    </div>
  </div>



  <!-- This example requires Tailwind CSS v2.0+ -->
<!-- <div class="bg-gray-50">
    <div class="max-w-7xl mx-auto py-12 px-4 divide-y-2 divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
      <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
        Wichtige <span class="text-indigo-600">Informationen</span>
      </h2>
      <div class="mt-6">
        <dl class="space-y-8 divide-y divide-gray-200">
          <div *ngFor="let info of informationen" class="pt-6 md:grid md:grid-cols-12 md:gap-8">
            <dt class="text-base font-medium text-gray-900 md:col-span-5">
              <span class="text-indigo-600">{{ info.date }}</span>, {{ info.titel }}
            </dt>
            <dd class="mt-2 md:mt-0 md:col-span-7">
              <p class="text-base text-gray-500">
               {{ info.text }}
              </p>
            </dd>
          </div>
          <div *ngIf="informationen.length == 0">
            <h2>Keine Informationen hinterlegt...</h2>
          </div>
  
        </dl>
      </div>
    </div>
  </div> -->
  
  <app-footer></app-footer>
  