import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-list-element',
  templateUrl: './calendar-list-element.component.html',
  styleUrls: ['./calendar-list-element.component.scss']
})
export class CalendarListElementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
