import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IUser, User } from './../_models/user';
import { LocalStorageService } from './local-storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private hostUrl = environment.hostUrl;


  constructor(
    private http: HttpClient,
    private localstorageService: LocalStorageService
  ) { }

  public getHeader(accesstoken: string) {
    const headers = ({
      'Authorization': `Bearer ${accesstoken}`
    });
    return headers
  }

  public getUsers(): Observable<IUser[]> {
    const user: User = this.localstorageService.getCurrentUser();
    const header = this.getHeader(user.accesstoken);

    return this.http.get<IUser[]>(`${this.hostUrl}/users`, {headers: header})
      .pipe(map((users: IUser[]) => {
        return users
      }));
  }

  public resetPassword(userId: string): Observable<any> {
    const user: User = this.localstorageService.getCurrentUser();
    const header = this.getHeader(user.accesstoken);

    return this.http.patch<any>(`${this.hostUrl}/users/reset-password/${userId}`, {Headers: header})
      .pipe(map((data: any) => {
        return data
      }));
  }
}
