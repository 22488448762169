import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kalender',
  templateUrl: './kalender.component.html',
  styleUrls: ['./kalender.component.scss']
})
export class KalenderComponent implements OnInit {

  openCalendar: Boolean = false;
  constructor() { }

  ngOnInit(): void {

  }

}
