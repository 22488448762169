import { Router } from '@angular/router';
import { LocalStorageService } from './../../../_services/local-storage.service';
import { User } from './../../../_models/user';
import { AuthenticationService } from './../../../_services/authentication.service';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styleUrls: ['./dashboard-navbar.component.scss']
})
export class DashboardNavbarComponent implements OnInit {
  user: User;

  isExpandedTickets: Boolean = false;
  isExpandedMobile: Boolean = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private localstorageService: LocalStorageService,
  ) {
    this.user = this.localstorageService.getCurrentUser();
   }

  ngOnInit(): void {
  }
  public logout(event?: Event): void {
    this.authenticationService.logout();
    this.router.navigate(['']);
  }

}
