import { getCategories } from './../../../../server/src/database/_functions/kategorie';
import { map } from 'rxjs/operators';
import { User } from './../_models/user';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Category } from '../_models/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private hostUrl = environment.hostUrl;

  constructor(
    private http: HttpClient,
    private localstorageService: LocalStorageService
  ) { }

  public getHeader(accesstoken: string) {
    const headers = ({
      'Authorization': `Bearer ${accesstoken}`
    });
    return headers
  }

  public getCategories(): Observable<Category[]> {
    const user: User = this.localstorageService.getCurrentUser();
    const header = this.getHeader(user.accesstoken);

    return this.http.get<Category[]>(`${this.hostUrl}/categories`, {headers: header})
      .pipe(map((categories: Category[]) => {
        return categories
      }));
  }
}
