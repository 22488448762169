import { LocalStorageService } from './../../../_services/local-storage.service';
import { Subject } from 'rxjs';
import { User } from './../../../_models/user';
import { Schortcut } from './../../../_models/shortcut';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-schortcuts-list',
  templateUrl: './schortcuts-list.component.html',
  styleUrls: ['./schortcuts-list.component.scss']
})
export class SchortcutsListComponent implements OnInit {
  eventsSubject: Subject<void> =  new Subject<void>();

  shortcuts: Schortcut[];
  user: User;
  loading: Boolean = false;

  constructor(
    private localstorageService: LocalStorageService,
  ) {
    this.user = this.localstorageService.getCurrentUser();
   }

  ngOnInit(): void {
  }

  public emitOpen() {
    this.eventsSubject.next();
  } 

  public addShortcut($event) {

  }

}
