import { CategoryService } from '../../../_services/category.service';
import { Category } from '../../../_models/category';
import { element } from 'protractor';
import { first } from 'rxjs/operators';
import { TicketService } from '../../../_services/ticket.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../../../_services/users.service';
import { RoomService } from '../../../_services/room.service';
import { Room } from '../../../_models/room';
import { User, IUser } from '../../../_models/user';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { Observable, Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-add-ticket',
  templateUrl: './add-ticket.component.html',
  styleUrls: ['./add-ticket.component.scss']
})
export class AddTicketComponent implements OnInit {
  isOpen: Boolean = false;
  user: User;
  users: IUser[];
  public rooms: Room[];
  categories: Category[];

  public message = {
    status: false,
    text: ''
  }

  loading: Boolean =  false;
  submitted:Boolean =  false;
  addTicketForm: FormGroup;


  private eventSubscription: Subscription;
  @Input() openEvent: Observable<void>;
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private localstorageService: LocalStorageService,
    private roomService: RoomService,
    private usersService: UsersService,
    private ticketService: TicketService,
    private categoryService: CategoryService,
  ) {
    this.user = this.localstorageService.getCurrentUser();
  }

  ngOnInit(): void {
    this.eventSubscription = this.openEvent.subscribe(() => {
      this.isOpen = !this.isOpen;
    });

    //Form
    this.addTicketForm = this.formBuilder.group({
      titel: ['', Validators.required],
      beschreibung: ['', Validators.required],
      dauer: [0, ],
      kategorie: ['', Validators.required],
      raum: ['', Validators.required],
      bearbeiter: [''],
      benachrichtigungBearbeiter: [false],
      status: ['nicht begonnen', Validators.required]
    });


    this.loadRooms();
    this.loadUsers();
    this.loadCategories();
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  public loadRooms(): void {
    this.rooms = [];
    this.roomService.getRooms().subscribe((data: Room[]) => {
      data.forEach(element => {
        this.rooms.push(element);
      })
    })
  }
  public loadUsers(): void {
    this.users = [];
    this.usersService.getUsers().subscribe((data: IUser[]) => {
      data.forEach((element: IUser) => {
        if (element.IsAdmin) this.users.push(element);
      })
    })
  }
  public loadCategories(): void {
    this.categories = [];
    this.categoryService.getCategories().subscribe((data: Category[]) => {
      data.forEach(element => {
        console.log(element);
        this.categories.push(element);
      })
    })
  }

  public onSubmit(): void {
    this.submitted = true;
    this.message.status = false;
   

    if (this.addTicketForm.invalid) {
      this.loading = false;
      return
    }

    this.loading = true;

    var ticket = {
      titel: this.addTicketForm.controls.titel.value,
      beschreibung: this.addTicketForm.controls.beschreibung.value.replaceAll('\n', '<br>'),
      ersteller: this.user.id,
      kategorie: this.addTicketForm.controls.kategorie.value,
      raum: this.addTicketForm.controls.raum.value,
      bearbeiter: this.addTicketForm.controls.bearbeiter.value,
      benachrichtigungBearbeiter: this.addTicketForm.controls.benachrichtigungBearbeiter.value,
      dauer: this.addTicketForm.controls.dauer.value,
      status: this.addTicketForm.controls.status.value,
    }
    
    this.ticketService.addTicket(ticket)
      .pipe(first())
      .subscribe(
        data => {
          this.ping.emit(data);
          this.isOpen = !this.isOpen;
          this.loading = false;
          this.addTicketForm.reset();
        },
        error => {
          this.message = {
            status: true,
            text: error.message
          }
          this.loading = false;
        }
      )

  }

}
