<div class="h-screen flex overflow-hidden bg-gray-100">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
          <div class="py-6">
              
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

				<div class="md:flex md:items-center md:justify-between">
					<div class="flex-1 min-w-0">
					<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
						Wichtige Informationen / Kalender
					</h2>
					</div>
					<div class="mt-4 flex md:mt-0 md:ml-4 mr-8">
						<!-- This example requires Tailwind CSS v2.0+ -->
						<span class="relative z-0 inline-flex shadow-sm rounded-md">
							<button (click)="openCalendar = false"  [ngClass]="{'bg-indigo-600': !openCalendar}" type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
								<svg class="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
								</svg>
								Listenansicht
							</button>
								
							<button (click)="openCalendar = true"  [ngClass]="{'bg-indigo-600': openCalendar}" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
								<svg class="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
								</svg>
								Kalenderansicht
							</button>
						</span>
						<button  type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
							Eintrag hinzufügen
						</button>
					</div>
				</div>
             
			<app-calendar *ngIf="openCalendar"></app-calendar>
			
			<app-calendar-list *ngIf="!openCalendar"></app-calendar-list>

                
              
              <!-- /End replace -->
            </div>
          </div>
        </main>
      </div>
  </div>