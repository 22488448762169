import { element } from 'protractor';
import { UsersService } from '../../../../_services/users.service';
import { IUser } from '../../../../_models/user';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-benutzer-list',
  templateUrl: './benutzer-list.component.html',
  styleUrls: ['./benutzer-list.component.scss']
})
export class BenutzerListComponent implements OnInit {
  users: IUser[];
  filteredUsers: IUser[];
  
  loading: Boolean = true;

  constructor(
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.loadUsers();
    this.loading = false;
  }

  public loadUsers(): void {
    this.users = [];
    this.filteredUsers = [];
    this.usersService.getUsers().subscribe((users: IUser[]) => {
      users.forEach(element => {
        this.users.push(element);
      });
      this.filteredUsers = this.users;
    },
    error => {
      console.log('error');
      console.log(error.message);
    })
  }

  public filterUsers(event?: Event): void {
    var searchValue = (<HTMLInputElement>document.getElementById('search')).value.toLowerCase();
    this.filteredUsers = this.users.filter(element => {
      return element.emailAdresse.toLowerCase().includes(searchValue) || element.Vorname.toLowerCase().includes(searchValue) || element.Nachname.toLowerCase().includes(searchValue)
    })
  }

}
