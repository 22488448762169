import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { User } from '../../../_models/user';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zu-bearbeitende-tickets',
  templateUrl: './zu-bearbeitende-tickets.component.html',
  styleUrls: ['./zu-bearbeitende-tickets.component.scss']
})
export class ZuBearbeitendeTicketsComponent implements OnInit {
  titel: string = "von mir zu bearbeitende Tickets";
  user: User;
  selection: Object = {};
  

  constructor(
    private localstorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.selection = {bearbeiter: params['id']}
   });
  }
  

  ngOnInit(): void {
    
  }


}
