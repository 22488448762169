<div class="h-screen flex overflow-hidden bg-gray-100">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <!-- <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button  class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open sidebar</span>
            
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div> -->
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
          <div class="py-6">

            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
              <!-- This example requires Tailwind CSS v2.0+ -->

              <app-schortcuts-list></app-schortcuts-list>
                
              
              <!-- /End replace -->
            </div>
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
              <!-- This example requires Tailwind CSS v2.0+ -->

              <app-ticket-list [titel]="titel" ></app-ticket-list>
              <div class="flex-row-reverse flex">
                <button type="button" class="mt-3 mr-8 flex   px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Alle Tickets ansehen
              </button>
              </div>
                
              
              <!-- /End replace -->
            </div>




            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
              <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div>
                 Kalender
                </div>
                <div>Prozesse</div>
              </div>


            </div>
          </div>
        </main>
      </div>
</div>

