

  <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <a>
        <div class="flex-1 flex flex-col p-8">
            <span class="inline-flex items-center justify-center h-32 w-32 rounded-full bg-gray-500 w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full">
                <span class="text-xl font-large leading-none text-white">{{ user.Vorname.charAt(0).toUpperCase() }}{{ user.Nachname.charAt(0).toUpperCase()}}</span>
              </span>
          <h3 class="mt-6 text-gray-900 text-sm font-medium">{{ user.Nachname}}, {{ user.Vorname }}</h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dt class="sr-only">Email</dt>
            <dd class="text-gray-500 text-sm">{{ user.emailAdresse }}</dd>
            <dt class="sr-only">Rolle</dt>
            <dd class="mt-3">
              <span *ngIf="user.IsAdmin" class="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">Admin</span>
              <span *ngIf="!user.IsAdmin" class="px-2 py-1 text-blue-800 text-xs font-medium bg-blue-100 rounded-full">Benutzer</span>
            </dd>
          </dl>
        </div>
    </a>
    <div>
      <div class="-mt-px flex divide-x divide-gray-200">
        <div class="w-0 flex-1 flex">
          <button (click)="changePassword($event)" href="#" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
            <!-- Heroicon name: mail -->
            <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            <span class="ml-3">Passwort zurücksetzen</span>
        </button>
        </div>
      </div>
    </div>
  </li>
  