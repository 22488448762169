<div class="antialiased sans-serif bg-gray-100 h-screen">
	<div>
		<div class=" px-0 py-2 md:py-8">
			  
			<!-- <div class="font-bold text-gray-800 text-xl mb-4">
				Schedule Tasks
			</div> -->

			<div class="bg-white rounded-lg shadow overflow-hidden">

				<div class="flex items-center justify-between py-2 px-6">
					<div>
						<span class="text-lg font-bold text-gray-800">{{ monthNames[month] }}</span>
						<span class="ml-1 text-lg text-gray-600 font-normal">{{ year }}</span>
                    </div>
                    
                    <!-- Button rückwärts -->
					<div class="border rounded-lg px-1" style="padding-top: 2px;">
						<button (click)="previousMonth()"
							type="button"
							class="leading-none rounded-lg transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 items-center">
							<svg class="h-6 w-6 text-gray-500 inline-flex leading-none"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
                            </svg> 
						</button>
                        <div class="border-r inline-flex h-6"></div>
                        		<!--  Button vorwärts -->
						<button (click)="nextMonth()"
							type="button"
							class="leading-none rounded-lg transition ease-in-out duration-100 inline-flex items-center cursor-pointer hover:bg-gray-200 p-1">
							<svg class="h-6 w-6 text-gray-500 inline-flex leading-none"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
							</svg>									  
						</button>
					</div>
                </div>
                

				<div class="-mx-1 -mb-1">
					<div class="flex flex-wrap" style="margin-bottom: -40px;">
						<div *ngFor="let day of days" class="px-2 py-2 days">	

								<div
									class="text-gray-600 text-sm uppercase tracking-wide font-bold text-center">{{ day }}</div>
							
						</div>
					</div>

					<div class="flex flex-wrap border-t border-l">
						<div *ngFor="let blankday of blankdays" class="blankDays text-center border-r border-b px-4 pt-2">
							<div 
								class=""	
							></div>
                        </div>	

                        
						<div *ngFor="let date of no_of_days" class=" px-4 pt-2 border-r border-b relative calendarObject">
                                <div (click)="openEventModal = !openEventModal" [ngClass]="{'bg-blue-500 text-white': isToday(date), 'text-gray-700 hover:bg-blue-200': !isToday(date)}"								
									class="inline-flex w-6 h-6 items-center justify-center cursor-pointer text-center leading-none rounded-full transition ease-in-out duration-100"
											>{{ date }}</div>
								<div [style.height.px]="80" class="overflow-y-auto mt-1">
									<!-- <div 
										class="absolute top-0 right-0 mt-2 mr-2 inline-flex items-center justify-center rounded-full text-sm w-6 h-6 bg-gray-700 text-white leading-none"
										x-show="events.filter(e => e.event_date === new Date(year, month, date).toDateString()).length"
										x-text="events.filter(e => e.event_date === new Date(year, month, date).toDateString()).length"></div> -->

									<!-- <template x-for="event in events.filter(e => new Date(e.event_date).toDateString() ===  new Date(year, month, date).toDateString() )">	
										<div
											class="px-2 py-1 rounded-lg mt-1 overflow-hidden border"
											:class="{
												'border-blue-200 text-blue-800 bg-blue-100': event.event_theme === 'blue',
												'border-red-200 text-red-800 bg-red-100': event.event_theme === 'red',
												'border-yellow-200 text-yellow-800 bg-yellow-100': event.event_theme === 'yellow',
												'border-green-200 text-green-800 bg-green-100': event.event_theme === 'green',
												'border-purple-200 text-purple-800 bg-purple-100': event.event_theme === 'purple'
											}"
										>
											<p x-text="event.event_title" class="text-sm truncate leading-tight"></p>
										</div>
									</template> -->
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  </div>
