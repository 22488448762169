import { LocalStorageService } from './../../../_services/local-storage.service';
import { User } from './../../../_models/user';
import { Schortcut } from './../../../_models/shortcut';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-schortcuts-list-element',
  templateUrl: './schortcuts-list-element.component.html',
  styleUrls: ['./schortcuts-list-element.component.scss']
})
export class SchortcutsListElementComponent implements OnInit {
  
  @Input() shortcut!: Schortcut;
  isExpanded: Boolean = false;
  user: User

  constructor(
    private localstorageService: LocalStorageService,
  ) {
    this.user = this.localstorageService.getCurrentUser();
   }

  ngOnInit(): void {
  }

}
