import { Subscription, Observable } from 'rxjs';
import { LocalStorageService } from './../../../_services/local-storage.service';
import { User } from './../../../_models/user';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-shortcut',
  templateUrl: './add-shortcut.component.html',
  styleUrls: ['./add-shortcut.component.scss']
})
export class AddShortcutComponent implements OnInit {

  isOpen: Boolean = false;
  user: User;

  loading: Boolean = false;

  public message = {
    status: false,
    text: ''
  }

  private eventSubscription: Subscription;
  @Input() openEvent: Observable<void>;
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private localstorageService: LocalStorageService
  ) {
    this.user = this.localstorageService.getCurrentUser();
   }

  ngOnInit(): void {
    this.eventSubscription = this.openEvent.subscribe(() => {
      this.isOpen = !this.isOpen;
    });
  }

  onSubmit(event?: Event) {
    console.log('test');
  }

}
