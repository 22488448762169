<div class="md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
    <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
        Direktlinks
    </h2>
    </div>
    <div *ngIf="user.isAdmin" class="mt-4 flex md:mt-0 md:ml-4 mr-8">
    <button (click)="emitOpen()" type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Shortcut hinzufügen
    </button>
    </div>
    
</div>
<br>
<hr>



<div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
    <div>
      <ul class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <app-schortcuts-list-element  [shortcut]="shortcut" *ngFor="let shortcut of shortcuts"></app-schortcuts-list-element>
        <app-schortcuts-list-element></app-schortcuts-list-element>
        <app-schortcuts-list-element></app-schortcuts-list-element>
        <app-schortcuts-list-element></app-schortcuts-list-element>
      </ul>
    </div>
  </div>


<app-add-shortcut (ping)="addShortcut($event)" [openEvent]="eventsSubject.asObservable()"></app-add-shortcut>
<app-loading-screen *ngIf="loading"></app-loading-screen>