import { LocalStorageService } from '../../../_services/local-storage.service';
import { IUser, User } from '../../../_models/user';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ticket } from '../../../_models/ticket';
import { TicketService } from '../../../_services/ticket.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  eventsSubject: Subject<void> =  new Subject<void>();


  ticketId: string;
  ticket: Ticket = {
      _id: '',
      dauer: 0,
      kategorie: {
        _id: '',
        bezeichnung: '',
      },
      ersteller: {
        _id: '',
        Vorname: '',
        Nachname: '',
        IsAdmin: false,
        emailAdresse: ''
      },
      raum: {
        _id: '',
        bezeichnung: ''
      },
      titel: '',
      beschreibung: '',
      status: '',
      comments: []

  };
  addCommentForm: FormGroup;
  submited: Boolean = false;
  loading: Boolean = false;
  user: User;

  constructor(
    private FormBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private ticketService: TicketService,
    private localstorageService: LocalStorageService
  ) { 
    this.user = this.localstorageService.getCurrentUser();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.ticketId = params['id'];
      this.loadTicket();
    });
    this.addCommentForm = this.FormBuilder.group({
      comment: ['', Validators.required]
    });
  }

  public changeUser(event: Ticket): void {
    this.ticket = event;
  }
  
  public loadTicket(): void {
    this.ticketService.getSingleTicket(this.ticketId).subscribe((data: Ticket) => {
      this.ticket = data;
    });
  }
  public emitOpen() {
    this.eventsSubject.next();
  } 

  public onCommentSubmit(event?: Event): void {
    this.submited = true;
    if (this.addCommentForm.invalid) return
    this.loading = true;

    var comment = this.addCommentForm.controls.comment.value.replaceAll('\n', '<br>');
    this.ticketService.addComment(comment, this.ticketId)
      .pipe(first())
      .subscribe(
        data => {
          this.ticket.comments.push(data);
          this.submited = false;
          this.addCommentForm.reset();
          this.loading = false;
          
        },
        error => {
          console.log('error');
          console.log(error);
          this.submited = false;
          this.loading = false;
        },
        
      )
  }

}
