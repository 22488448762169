<!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="message.status" class="rounded-md bg-red-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <!-- Heroicon name: x-circle -->
        <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />        </svg>
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">
          Fehler
        </h3>
        <div class="mt-2 text-sm text-red-700">
          <p>
            {{ message.text }}
          </p>
        </div>
      </div>
    </div>
  </div>
  
<form [formGroup]="loginForm" (ngSubmit)="onSubmit($event)" class="space-y-6">
    <div>
        <label for="email" class="block text-sm font-medium text-gray-700">
            E-Mail
        </label>
        <div class="mt-1">
            <input type="email" formControlName="email" [ngClass]="{ 'border-2 border-red-500': submitted && loginForm.controls.email.errors  }" name="email" type="email" autocomplete="email" 
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <div *ngIf="submitted && loginForm.controls.email.errors" class="invalid-feedback">
                    <div *ngIf="loginForm.controls.email.errors.required">Email is required</div>
                </div>
        </div>
    </div>

    <div>
        <label for="password" class="block text-sm font-medium text-gray-700">
            Passwort
        </label>
        <div class="mt-1">
            <input type="password"  formControlName="password" [ngClass]="{ 'border-2 border-red-500': submitted && loginForm.controls.email.errors  }" type="password" autocomplete="current-password" 
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <div *ngIf="submitted && loginForm.controls.password.errors" class="invalid-feedback">
                    <div *ngIf="loginForm.controls.password.errors.required">Password is required</div>
                </div>
            </div>
    </div>

    <!-- <div class="flex items-center justify-between">
        <div class="text-sm">
            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                Passwort vergessen?
            </a>
        </div>
    </div> -->
    <p>Die Anmeldedaten für das Ticketsystem haben Sie per Email von <span class="text-indigo-500">ticket.system@gs-bk.de</span> erhalten. <br> Bei Fragen wenden Sie sich bitte an <a class="text-indigo-500" href="mailto:ba@gs-bk.de">J. Baur</a>.</p>

    <div>
        <button type="submit"
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <svg class="spinner h-5 w-5 mr-3" viewBox="0 0 24 24">
                <!-- ... -->
              </svg>
            Anmelden
        </button>
    </div>
</form>



