<div class="h-screen flex overflow-hidden bg-gray-100">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
          <div class="py-6">
              

            <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <!-- Replace with your content -->
              <!-- This example requires Tailwind CSS v2.0+ -->
              <main class="flex-1 relative overflow-y-auto focus:outline-none" tabindex="-1">
                <div class="py-8 xl:py-10">
                  <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 xl:max-w-5xl xl:grid xl:grid-cols-3">
                    <div class="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
                      <div>
                        <div>
                          <div class="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                            <div>
                              <h1 class="text-2xl font-bold text-gray-900">{{ ticket.titel }}</h1>
                              <p class="mt-2 text-sm text-gray-500">
                               Erstellt von
                                <a href="mailto:{{ ticket.ersteller.emailAdresse }}" class="font-medium text-gray-900">{{ ticket.ersteller.Vorname }} {{ ticket.ersteller.Nachname }} ({{ ticket.ersteller.emailAdresse }})</a>
                               
                              </p>
                            </div>
                            <div class="mt-4 flex space-x-3 md:mt-0">
                              <button *ngIf="user.isAdmin || user.id == ticket.ersteller._id" (click)="emitOpen()" type="button" class="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                                <!-- Heroicon name: pencil -->
                                <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                                <span>Bearbeiten</span>
                              </button>
                            </div>
                          </div>
                          
                          <aside class="mt-8 xl:hidden">
                            <h2 class="sr-only">Details</h2>
                            <div class="space-y-5">
                              <div class="flex items-center space-x-2">
                                <!-- Heroicon name: lock-open -->
                                <svg class="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
                                </svg>
                                <span class="text-green-700 text-sm font-medium">{{ ticket.status }}</span>
                              </div>
                              <div class="flex items-center space-x-2">
                                <!-- Heroicon name: chat-alt -->
                                <svg  class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                </svg>
                                <span class="text-gray-900 text-sm font-medium">{{ ticket.kategorie.bezeichnung }}</span>
                              </div>
                              <div class="flex items-center space-x-2">
                                <!-- Heroicon name: chat-alt -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                                </svg>
                                <span class="text-gray-900 text-sm font-medium">{{ ticket.comments.length }} Kommentare</span>
                              </div>
                              <div class="flex items-center space-x-2">
                                <!-- Heroicon name: chat-alt -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span class="text-gray-900 text-sm font-medium">{{ ticket.dauer }} Stunden</span>
                              </div>
                              <div class="flex items-center space-x-2">
                                <!-- Heroicon name: calendar -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                                </svg>
                                <span class="text-gray-900 text-sm font-medium">Erstellt am <time datetime="2020-12-02">{{ ticket.createdReadable}}</time></span>
                              </div>
                            </div>
                            <div class="mt-6 border-t border-b border-gray-200 py-6 space-y-8">
                              <div>
                                <h2 class="text-sm font-medium text-gray-500">Zugewiesen an</h2>
                                <ul class="mt-3 space-y-3">
                                    <li *ngIf="!ticket.bearbeiter">
                                        <h2>Es wurde noch kein Bearbeiter hinzugewiesen</h2>
                                    </li>
                                  <li *ngIf="ticket.bearbeiter" class="flex justify-start">
                                    <a href="#" class="flex items-center space-x-3">
                                        <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                            <span class="font-medium leading-none text-white">{{ ticket.bearbeiter.Vorname.charAt(0).toUpperCase() }}{{ ticket.bearbeiter.Nachname.charAt(0).toUpperCase() }}</span>
                                          </span>
                                      <div class="text-sm font-medium text-gray-900">{{ ticket.bearbeiter.Vorname }} {{ ticket.bearbeiter.Nachname }} ({{ ticket.bearbeiter.emailAdresse }})</div>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </aside>
                          <div class="py-3 xl:pt-6 xl:pb-0">
                            <h2 class="sr-only">Beschreibung</h2>
                            <div class="prose max-w-none">
                                <p [innerHTML]="ticket.beschreibung">  </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <section aria-labelledby="activity-title" class="mt-8 xl:mt-10">
                        <div>
                          <div class="divide-y divide-gray-200">
                            <div class="pb-4">
                              <h2 id="activity-title" class="text-lg font-medium text-gray-900">Aktivität</h2>
                            </div>
                            <div class="pt-6">
                              <!-- Activity feed-->
                              <div class="flow-root">
                                <ul class="-mb-8">
                                    <li *ngIf="ticket.comments.length == 0">
                                        <h1>Es wurden noch Keine Kommentare abgegeben...</h1>
                                    </li>
                                  <li *ngFor="let comment of ticket.comments">
                                    <div class="relative pb-8">
                                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                      <div class="relative flex items-start space-x-3">
                                            <!-- Heroicon name: chat-alt -->
                                            <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                                <span class="font-medium leading-none text-white">{{ comment.ersteller.Vorname.charAt(0).toUpperCase() }}{{ comment.ersteller.Nachname.charAt(0).toUpperCase() }}</span>
                                              </span>
                                        <div class="min-w-0 flex-1">
                                          <div>
                                            <div class="text-sm">
                                              <a href="mailto:{{ comment.ersteller.emailAdresse }}" class="font-medium text-gray-900">{{ comment.ersteller.Vorname }} {{ comment.ersteller.Nachname }} ({{ comment.ersteller.emailAdresse }}) </a>
                                            </div>
                                            <p class="mt-0.5 text-sm text-gray-500">
                                              kommentiert am {{ comment.createdReadable }}
                                            </p>
                                          </div>
                                          <div class="mt-2 text-sm text-gray-700">
                                            <p [innerHTML]="comment.text">
                                              
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <aside class="hidden xl:block xl:pl-8">
                      <h2 class="sr-only">Details</h2>
                      <div class="space-y-5">
                        <div class="flex items-center space-x-2">
                          <!-- Heroicon name: lock-open -->
                          <svg class="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
                          </svg>
                          <span class="text-green-700 text-sm font-medium">{{ ticket.status }}</span>
                        </div>
                        <div class="flex items-center space-x-2">
                          <!-- Heroicon name: chat-alt -->
                          <svg  class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                          </svg>
                          <span class="text-gray-900 text-sm font-medium">{{ ticket.kategorie.bezeichnung }}</span>
                        </div>
                        <div class="flex items-center space-x-2">
                          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                          </svg>
                    

                          <span class="text-gray-900 text-sm font-medium">{{ ticket.raum.bezeichnung }}</span>
                        </div>
                        <div class="flex items-center space-x-2">
                          <!-- Heroicon name: chat-alt -->
                          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                          </svg>
                          <span class="text-gray-900 text-sm font-medium">{{ ticket.comments.length }} Kommentare</span>
                        </div>
                        <div class="flex items-center space-x-2">
                          <!-- Heroicon name: chat-alt -->
                          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <span class="text-gray-900 text-sm font-medium">{{ ticket.dauer}} Stunden</span>
                        </div>

                        <div class="flex items-center space-x-2">
                          <!-- Heroicon name: calendar -->
                          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                          </svg>
                          <span class="text-gray-900 text-sm font-medium">Erstellt am <time datetime="2020-12-02">{{ ticket.createdReadable }}</time></span>
                        </div>
                      </div>
                      <div class="mt-6 border-t border-gray-200 py-6 space-y-8">
                        <div>
                          <h2 class="text-sm font-medium text-gray-500">Zugewiesen an</h2>
                          <ul class="mt-3 space-y-3">
                              <li *ngIf="!ticket.bearbeiter">
                                <h2>Dem Ticket wurde noch kein Bearbeiter zugewiesen.</h2>
                              </li>
                            <li *ngIf="ticket.bearbeiter" class="flex justify-start">
                              <a href="#" class="flex items-center space-x-3">
                                <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                    <span class="font-medium leading-none text-white">{{ ticket.bearbeiter.Vorname.charAt(0).toUpperCase() }}{{ ticket.bearbeiter.Nachname.charAt(0).toUpperCase() }}</span>
                                  </span>
                                <div class="text-sm font-medium text-gray-900">{{ ticket.bearbeiter.Vorname}} {{ ticket.bearbeiter.Nachname }} ({{ ticket.bearbeiter.emailAdresse }})</div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </aside>



                  
                    
            
                
              
              <!-- /End replace -->
            </div>
            
          </div>
          
        </main>
        
      </div>
      <br><br>
      <form [formGroup]="addCommentForm" (ngSubmit)="onCommentSubmit($event)">
        <div class="max-w-5xl mx-auto px-4 sm:px-6 md:px-8">
          <div>
            <label for="about" class="block text-sm font-medium text-gray-700">
              Kommentar hinzufügen
            </label>
            <div class="mt-1">
              <textarea formControlName="comment" [ngClass]="{ 'border-2 border-red-500': submited && addCommentForm.controls.comment.errors  }" rows="3" class="shadow-sm focus:ring-light-blue-500 focus:border-light-blue-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
            </div>
          </div>
          <br>
          <button type="submit" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Kommentieren
          </button>
        
        </div>
      </form>
</div>

<app-update-ticket (addedUser)="changeUser($event)" [openEvent]="eventsSubject.asObservable()" [currentTicket]="ticket" ></app-update-ticket>
<app-loading-screen *ngIf="loading"></app-loading-screen>