import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { IUser } from '../../../../_models/user';
import { TicketService } from '../../../../_services/ticket.service';
import { Ticket, selection } from '../../../../_models/ticket';
import { Component, Input, OnInit } from '@angular/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})


export class TicketListComponent implements OnInit {
  eventsSubject: Subject<void> =  new Subject<void>();


  public tickets: Ticket[];
  public filteredTickets: Ticket[];
  @Input() selection: selection;
  @Input() titel: string;
  public loading: Boolean = true;
  


  constructor(
    private ticketService: TicketService,

  ) {
    
  }

  ngOnInit(): void {
    this.loadTickets();
    this.loading = false;

  }

  public loadTickets(): void {
    this.tickets = [];
    this.filteredTickets = [];
    this.ticketService.getTickets(this.selection).subscribe((data: Ticket[]) => {
      data.forEach(element => {
        this.tickets.push(element);
        
      })
      this.filteredTickets = this.tickets;
    },
    error => {
      console.log('error');
      console.log(error.message);
    })

  }
  public addUser(event: Ticket): void {
    if (this.selection.bearbeiter && this.selection.bearbeiter == event.bearbeiter._id) {
      this.tickets.unshift(event);
      return
    }
    if (this.selection.ersteller && this.selection.ersteller == event.ersteller._id) {
      this.tickets.unshift(event);
      return
    }
    this.tickets.unshift(event);

  }
  public emitOpen() {
    this.eventsSubject.next();
  } 

  public filterTickets(event?: Event) : void {

   var searchInput = (<HTMLInputElement>document.getElementById('search')).value.toLowerCase();
    this.filteredTickets = this.tickets.filter(element => {
      return element.titel.toLowerCase().includes(searchInput) || element.beschreibung.toLowerCase().includes(searchInput) || element.raum.bezeichnung.toLowerCase().includes(searchInput) || element.kategorie.bezeichnung.toLowerCase().includes(searchInput);
    });
    
  }


}
