<div class="h-screen flex overflow-hidden bg-gray-100">
  <app-dashboard-navbar></app-dashboard-navbar>
  <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
        <div class="py-6">
            
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <!-- This example requires Tailwind CSS v2.0+ -->

            <app-ticket-list [titel]="titel" [selection]="selection"></app-ticket-list>
              
            
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
</div>

