<div class="md:flex md:items-center md:justify-between mr-8">
    <div class="flex-1 min-w-0 ">
    <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
        {{ titel }}
    </h2>
    </div>
    <div class="mt-3">
        <div class="mb-3 pt-0">
            <input (input)="filterTickets($event)" id="search" type="text" placeholder="Suchen..." class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"/>
          </div>
    </div>
    <div class="mt-4 flex md:mt-0 md:ml-4">
    <button (click)="emitOpen()" type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Ticket hinzufügen
    </button>
    </div>
    
</div>
<br>
<hr>


<div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
<!-- Replace with your content -->
<!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="filteredTickets.length == 0">
    <h1>Es sind keine Tickets in dieser Kategorie vorhanden...</h1>
</div>
<div   class="bg-white shadow overflow-hidden sm:rounded-md">
<ul class="divide-y divide-gray-200">

<app-ticket-list-element   [ticket]="ticket" *ngFor="let ticket of filteredTickets"></app-ticket-list-element>
</ul>
</div>
</div>

<app-add-ticket (ping)="addUser($event)" [openEvent]="eventsSubject.asObservable()"></app-add-ticket>

<app-loading-screen *ngIf="loading"></app-loading-screen>