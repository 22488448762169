import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  openEventModal: Boolean = false;

  monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  days = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
  initializationDate = new Date();

  // Calendar init
  month: number;
  year: number;
  no_of_days = [];
  blankdays = [];
  

  // Interfaces erstellen
  events = [];


  

  constructor() { }

  ngOnInit(): void {
    this.initDate();
    this.getNoOfDays();
  }

  public initDate(): void {
    var today = new Date();
    this.month = today.getMonth();
    this.year = today.getFullYear();
  }

  public getNoOfDays(): void {
    var daysInMonth = new Date(this.year, this.month +1, 0).getDate();

    var dayOfWeek = new Date(this.year, this.month).getDay();
    var blankdaysArray = [];
    for (var i = 1; i<= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }
    var daysArray = [];
    for (var i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    this.blankdays = blankdaysArray;
    this.no_of_days = daysArray;
  }

  public isToday(date): Boolean {
    const today = new Date();
    const d = new Date(this.year, this.month, date);

    return today.toDateString() === d.toDateString() ? true : false
  }

  public previousMonth(): void {
    var date = new Date(this.year, this.month - 1);
    this.year = date.getFullYear();
    this.month = date.getMonth();
    this.getNoOfDays();
  }

  public nextMonth(): void {
    var date = new Date(this.year, this.month + 1);
    this.year = date.getFullYear();
    this.month = date.getMonth();
    this.getNoOfDays();
  }
}
